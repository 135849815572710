


@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWx8QDO-WyrubOA.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWxEQDO-Wyrs.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMaC82U-ro.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxMaC82U.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY5KcC-wLOjAUw.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcC-wLOj.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: "DIN-Next-W01-Light";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "DIN-Next-W02-Light";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/48e5a0e1-2d56-46e5-8fc4-3d6d5c973cbf.eot?#iefix");
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/48e5a0e1-2d56-46e5-8fc4-3d6d5c973cbf.eot?#iefix") format("eot"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/07d62b21-8d7a-4c36-be86-d32ab1089972.woff") format("woff"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c0050890-bbed-44b9-94df-2611d72dbb06.ttf") format("truetype"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/9f774d17-c03a-418e-a375-34f3beecbc7a.svg#9f774d17-c03a-418e-a375-34f3beecbc7a") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "DIN-Next-W10-Light";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3d009cd7-c8fe-40c0-93da-74f4ea8c530b.eot?#iefix");
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3d009cd7-c8fe-40c0-93da-74f4ea8c530b.eot?#iefix") format("eot"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/a9e95a29-98a7-404a-90ee-1929ad09c696.woff") format("woff"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0a7663fd-eae8-4e50-a67a-225271f8cceb.ttf") format("truetype"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/58ae9be9-5d95-44b6-8b6c-e6da6a46822c.svg#58ae9be9-5d95-44b6-8b6c-e6da6a46822c") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W01-Reg";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W05-Reg";
    src: url("http://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff2") format("woff2"),
    url("http://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica-W01-Light";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix");
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix") format("eot"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/03805817-4611-4dbc-8c65-0f73031c3973.woff") format("woff"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d5f9f72d-afb7-4c57-8348-b4bdac42edbb.ttf") format("truetype"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/05ad458f-263b-413f-b054-6001a987ff3e.svg#05ad458f-263b-413f-b054-6001a987ff3e") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica-W02-Light";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix");
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix") format("eot"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80c34ad2-27c2-4d99-90fa-985fd64ab81a.woff") format("woff"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8cb02c2-5b58-48d8-9501-8d02869154c2.ttf") format("truetype"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/92c941ea-2b06-4b72-9165-17476d424d6c.svg#92c941ea-2b06-4b72-9165-17476d424d6c") format("svg");
    font-display: swap;
}

#masterPage:not(.landingPage) #PAGES_CONTAINER {
    margin-top: 0px;
    margin-bottom: 0px;
}

#masterPage.landingPage #SITE_HEADER {
    display: none;
}

#masterPage.landingPage #SITE_FOOTER {
    display: none;
}

#masterPage.landingPage #SITE_HEADER-placeholder {
    display: none;
}

#masterPage.landingPage #SITE_FOOTER-placeholder {
    display: none;
}

#SITE_CONTAINER.focus-ring-active :not(.has-custom-focus):not(.ignore-focus):not([tabindex="-1"]):focus,
#SITE_CONTAINER.focus-ring-active :not(.has-custom-focus):not(.ignore-focus):not([tabindex="-1"]):focus~.wixSdkShowFocusOnSibling {
    --focus-ring-box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #116dff;
    box-shadow: var(--focus-ring-box-shadow) !important;
    z-index: 1;
}

.has-inner-focus-ring {
    --focus-ring-box-shadow: inset 0 0 0 1px #ffffff, inset 0 0 0 3px #116dff !important;
}

#masterPage {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

#SITE_HEADER {
    z-index: 50;
    --above-all-in-container: 10000;
}

#PAGES_CONTAINER {
    --pinned-layer-in-container: 51;
    --above-all-in-container: 49;
}

#SITE_FOOTER {
    --pinned-layer-in-container: 52;
    --above-all-in-container: 49;
}

:root,
:host,
.spxThemeOverride,
.max-width-container {
    --color_0: 255, 255, 255;
    --color_1: 255, 255, 255;
    --color_2: 0, 0, 0;
    --color_3: 237, 28, 36;
    --color_4: 0, 136, 203;
    --color_5: 255, 203, 5;
    --color_6: 114, 114, 114;
    --color_7: 176, 176, 176;
    --color_8: 255, 255, 255;
    --color_9: 114, 114, 114;
    --color_10: 176, 176, 176;
    --color_11: 255, 255, 255;
    --color_12: 249, 249, 249;
    --color_13: 212, 212, 210;
    --color_14: 168, 168, 167;
    --color_15: 194, 178, 128;
    --color_16: 237, 198, 198;
    --color_17: 218, 164, 165;
    --color_18: 200, 101, 103;
    --color_19: 133, 67, 69;
    --color_20: 67, 34, 34;
    --color_21: 205, 208, 213;
    --color_22: 156, 162, 171;
    --color_23: 115, 120, 130;
    --color_24: 27,111,27;
    --color_25: 30, 36, 46;
    --color_26: 208, 187, 199;
    --color_27: 162, 136, 151;
    --color_28: 115, 79, 100;
    --color_29: 77, 53, 67;
    --color_30: 38, 26, 33;
    --color_31: 205, 231, 203;
    --color_32: 160, 207, 156;
    --color_33: 120, 155, 117;
    --color_34: 80, 104, 78;
    --color_35: 40, 52, 39;
    --color_36: 255, 255, 255;
    --color_37: 65, 65, 65;
    --color_38: 249, 249, 249;
    --color_39: 212, 212, 210;
    --color_40: 168, 168, 167;
    --color_41: 200, 101, 103;
    --color_42: 65, 65, 65;
    --color_43: 212, 212, 210;
    --color_44: 255, 255, 255;
    --color_45: 65, 65, 65;
    --color_46: 65, 65, 65;
    --color_47: 168, 168, 167;
    --color_48: 200, 101, 103;
    --color_49: 200, 101, 103;
    --color_50: 255, 255, 255;
    --color_51: 255, 255, 255;
    --color_52: 200, 101, 103;
    --color_53: 200, 101, 103;
    --color_54: 212, 212, 210;
    --color_55: 212, 212, 210;
    --color_56: 255, 255, 255;
    --color_57: 255, 255, 255;
    --color_58: 200, 101, 103;
    --color_59: 200, 101, 103;
    --color_60: 200, 101, 103;
    --color_61: 200, 101, 103;
    --color_62: 255, 255, 255;
    --color_63: 255, 255, 255;
    --color_64: 212, 212, 210;
    --color_65: 212, 212, 210;
    --font_0: normal normal normal 56px/normal 'libre baskerville', serif;
    --font_1: normal normal normal 16px/1.4em din-next-w01-light, din-next-w02-light, din-next-w10-light, sans-serif;
    --font_2: normal normal bold 38px/normal proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --font_3: normal normal normal 130px/1.4em 'times new roman', times, serif;
    --font_4: normal normal normal 115px/1.4em 'times new roman', times, serif;
    --font_5: normal normal bold 35px/1.4em 'libre baskerville', serif;
    --font_6: normal normal normal 17px/1.4em helvetica-w01-light, helvetica-w02-light, sans-serif;
    --font_7: normal normal normal 20px/1.4em helvetica-w01-light, helvetica-w02-light, sans-serif;
    --font_8: normal normal normal 17px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --font_9: normal normal normal 16px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --font_10: normal normal normal 12px/1.4em din-next-w01-light, din-next-w02-light, din-next-w10-light, sans-serif;
    --wix-ads-height: 0px;
    --sticky-offset: 0px;
    --wix-ads-top-height: 0px;
    --vh-unit: calc((100vh - 0px) / 100);
    --site-width: 980px;
    --above-all-z-index: 100000;
    --portals-z-index: 100001;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --minViewportSize: 320;
    --maxViewportSize: 1920;
    --theme-spx-ratio: 1px;
    --scaling-factor: min((100 * var(--one-unit)), var(--section-max-width));
    --customScaleViewportLimit: clamp(var(--minViewportSize) * 1px, 100 * var(--one-unit), min(var(--section-max-width), var(--maxViewportSize) * 1px));
}

.font_0 {
    font: var(--font_0);
    color: rgb(0, 0, 0);
    letter-spacing: normal;
}

.font_1 {
    font: var(--font_1);
    color: rgb(var(--color_14));
    letter-spacing: 0em;
}

.font_2 {
    font: var(--font_2);
    color: rgb(166, 194, 227);
    letter-spacing: normal;
}

.font_3 {
    font: var(--font_3);
    color: rgb(var(--color_19));
    letter-spacing: 0em;
}

.font_4 {
    font: var(--font_4);
    color: rgb(var(--color_25));
    letter-spacing: 0em;
}

.font_5 {
    font: var(--font_5);
    color: rgb(0, 0, 0);
    letter-spacing: 0em;
}

.font_6 {
    font: var(--font_6);
    color: rgb(var(--color_18));
    letter-spacing: 0em;
}

.font_7 {
    font: var(--font_7);
    color: rgb(var(--color_23));
    letter-spacing: 0em;
}

.font_8 {
    font: var(--font_8);
    color: rgb(var(--color_25));
    letter-spacing: 0em;
}

.font_9 {
    font: var(--font_9);
    color: rgb(0, 0, 0);
    letter-spacing: normal;
}

.font_10 {
    font: var(--font_10);
    color: rgb(var(--color_14));
    letter-spacing: 0em;
}

.color_0 {
    color: rgb(var(--color_0));
}

.color_1 {
    color: rgb(var(--color_1));
}

.color_2 {
    color: rgb(var(--color_2));
}

.color_3 {
    color: rgb(var(--color_3));
}

.color_4 {
    color: rgb(var(--color_4));
}

.color_5 {
    color: rgb(var(--color_5));
}

.color_6 {
    color: rgb(var(--color_6));
}

.color_7 {
    color: rgb(var(--color_7));
}

.color_8 {
    color: rgb(var(--color_8));
}

.color_9 {
    color: rgb(var(--color_9));
}

.color_10 {
    color: rgb(var(--color_10));
}

.color_11 {
    color: rgb(var(--color_11));
}

.color_12 {
    color: rgb(var(--color_12));
}

.color_13 {
    color: rgb(var(--color_13));
}

.color_14 {
    color: rgb(var(--color_14));
}

.color_15 {
    color: rgb(var(--color_15));
}

.color_16 {
    color: rgb(var(--color_16));
}

.color_17 {
    color: rgb(var(--color_17));
}

.color_18 {
    color: rgb(var(--color_18));
}

.color_19 {
    color: rgb(var(--color_19));
}

.color_20 {
    color: rgb(var(--color_20));
}

.color_21 {
    color: rgb(var(--color_21));
}

.color_22 {
    color: rgb(var(--color_22));
}

.color_23 {
    color: rgb(var(--color_23));
}

.color_24 {
    color: rgb(var(--color_24));
}

.color_25 {
    color: rgb(var(--color_25));
}

.color_26 {
    color: rgb(var(--color_26));
}

.color_27 {
    color: rgb(var(--color_27));
}

.color_28 {
    color: rgb(var(--color_28));
}

.color_29 {
    color: rgb(var(--color_29));
}

.color_30 {
    color: rgb(var(--color_30));
}

.color_31 {
    color: rgb(var(--color_31));
}

.color_32 {
    color: rgb(var(--color_32));
}

.color_33 {
    color: rgb(var(--color_33));
}

.color_34 {
    color: rgb(var(--color_34));
}

.color_35 {
    color: rgb(var(--color_35));
}

.color_36 {
    color: rgb(var(--color_36));
}

.color_37 {
    color: rgb(var(--color_37));
}

.color_38 {
    color: rgb(var(--color_38));
}

.color_39 {
    color: rgb(var(--color_39));
}

.color_40 {
    color: rgb(var(--color_40));
}

.color_41 {
    color: rgb(var(--color_41));
}

.color_42 {
    color: rgb(var(--color_42));
}

.color_43 {
    color: rgb(var(--color_43));
}

.color_44 {
    color: rgb(var(--color_44));
}

.color_45 {
    color: rgb(var(--color_45));
}

.color_46 {
    color: rgb(var(--color_46));
}

.color_47 {
    color: rgb(var(--color_47));
}

.color_48 {
    color: rgb(var(--color_48));
}

.color_49 {
    color: rgb(var(--color_49));
}

.color_50 {
    color: rgb(var(--color_50));
}

.color_51 {
    color: rgb(var(--color_51));
}

.color_52 {
    color: rgb(var(--color_52));
}

.color_53 {
    color: rgb(var(--color_53));
}

.color_54 {
    color: rgb(var(--color_54));
}

.color_55 {
    color: rgb(var(--color_55));
}

.color_56 {
    color: rgb(var(--color_56));
}

.color_57 {
    color: rgb(var(--color_57));
}

.color_58 {
    color: rgb(var(--color_58));
}

.color_59 {
    color: rgb(var(--color_59));
}

.color_60 {
    color: rgb(var(--color_60));
}

.color_61 {
    color: rgb(var(--color_61));
}

.color_62 {
    color: rgb(var(--color_62));
}

.color_63 {
    color: rgb(var(--color_63));
}

.color_64 {
    color: rgb(var(--color_64));
}

.color_65 {
    color: rgb(var(--color_65));
}

.backcolor_0 {
    background-color: rgb(var(--color_0));
}

.backcolor_1 {
    background-color: rgb(var(--color_1));
}

.backcolor_2 {
    background-color: rgb(var(--color_2));
}

.backcolor_3 {
    background-color: rgb(var(--color_3));
}

.backcolor_4 {
    background-color: rgb(var(--color_4));
}

.backcolor_5 {
    background-color: rgb(var(--color_5));
}

.backcolor_6 {
    background-color: rgb(var(--color_6));
}

.backcolor_7 {
    background-color: rgb(var(--color_7));
}

.backcolor_8 {
    background-color: rgb(var(--color_8));
}

.backcolor_9 {
    background-color: rgb(var(--color_9));
}

.backcolor_10 {
    background-color: rgb(var(--color_10));
}

.backcolor_11 {
    background-color: rgb(var(--color_11));
}

.backcolor_12 {
    background-color: rgb(var(--color_12));
}

.backcolor_13 {
    background-color: rgb(var(--color_13));
}

.backcolor_14 {
    background-color: rgb(var(--color_14));
}

.backcolor_15 {
    background-color: rgb(var(--color_15));
}

.backcolor_16 {
    background-color: rgb(var(--color_16));
}

.backcolor_17 {
    background-color: rgb(var(--color_17));
}

.backcolor_18 {
    background-color: rgb(var(--color_18));
}

.backcolor_19 {
    background-color: rgb(var(--color_19));
}

.backcolor_20 {
    background-color: rgb(var(--color_20));
}

.backcolor_21 {
    background-color: rgb(var(--color_21));
}

.backcolor_22 {
    background-color: rgb(var(--color_22));
}

.backcolor_23 {
    background-color: rgb(var(--color_23));
}

.backcolor_24 {
    background-color: rgb(var(--color_24));
}

.backcolor_25 {
    background-color: rgb(var(--color_25));
}

.backcolor_26 {
    background-color: rgb(var(--color_26));
}

.backcolor_27 {
    background-color: rgb(var(--color_27));
}

.backcolor_28 {
    background-color: rgb(var(--color_28));
}

.backcolor_29 {
    background-color: rgb(var(--color_29));
}

.backcolor_30 {
    background-color: rgb(var(--color_30));
}

.backcolor_31 {
    background-color: rgb(var(--color_31));
}

.backcolor_32 {
    background-color: rgb(var(--color_32));
}

.backcolor_33 {
    background-color: rgb(var(--color_33));
}

.backcolor_34 {
    background-color: rgb(var(--color_34));
}

.backcolor_35 {
    background-color: rgb(var(--color_35));
}

.backcolor_36 {
    background-color: rgb(var(--color_36));
}

.backcolor_37 {
    background-color: rgb(var(--color_37));
}

.backcolor_38 {
    background-color: rgb(var(--color_38));
}

.backcolor_39 {
    background-color: rgb(var(--color_39));
}

.backcolor_40 {
    background-color: rgb(var(--color_40));
}

.backcolor_41 {
    background-color: rgb(var(--color_41));
}

.backcolor_42 {
    background-color: rgb(var(--color_42));
}

.backcolor_43 {
    background-color: rgb(var(--color_43));
}

.backcolor_44 {
    background-color: rgb(var(--color_44));
}

.backcolor_45 {
    background-color: rgb(var(--color_45));
}

.backcolor_46 {
    background-color: rgb(var(--color_46));
}

.backcolor_47 {
    background-color: rgb(var(--color_47));
}

.backcolor_48 {
    background-color: rgb(var(--color_48));
}

.backcolor_49 {
    background-color: rgb(var(--color_49));
}

.backcolor_50 {
    background-color: rgb(var(--color_50));
}

.backcolor_51 {
    background-color: rgb(var(--color_51));
}

.backcolor_52 {
    background-color: rgb(var(--color_52));
}

.backcolor_53 {
    background-color: rgb(var(--color_53));
}

.backcolor_54 {
    background-color: rgb(var(--color_54));
}

.backcolor_55 {
    background-color: rgb(var(--color_55));
}

.backcolor_56 {
    background-color: rgb(var(--color_56));
}

.backcolor_57 {
    background-color: rgb(var(--color_57));
}

.backcolor_58 {
    background-color: rgb(var(--color_58));
}

.backcolor_59 {
    background-color: rgb(var(--color_59));
}

.backcolor_60 {
    background-color: rgb(var(--color_60));
}

.backcolor_61 {
    background-color: rgb(var(--color_61));
}

.backcolor_62 {
    background-color: rgb(var(--color_62));
}

.backcolor_63 {
    background-color: rgb(var(--color_63));
}

.backcolor_64 {
    background-color: rgb(var(--color_64));
}

.backcolor_65 {
    background-color: rgb(var(--color_65));
}

#masterPage:not(.landingPage) {
    --top-offset: var(--header-height);
    --header-height: 113px;
}

#masterPage.landingPage {
    --top-offset: 0px;
}

#SITE_HEADER {
    --bg: 251, 251, 251;
    --shd: none;
    --brwt: 0px;
    --brd: 47, 46, 46;
    --brwb: 0px;
    --bgctr: 255, 255, 255;
    --rd: 0px;
    --alpha-bg: 1;
    --alpha-bgctr: 0;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#SITE_HEADER {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=SITE_HEADERinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=SITE_HEADERinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=SITE_HEADERinlineContent-gridContainer]>[id="comp-jy05jif9"] {
    position: relative;
    margin: 34px 0px 29px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_HEADERinlineContent-gridContainer]>[id="comp-isyhm8wh"] {
    position: relative;
    margin: 29px 0px 34px calc((100% - 980px) * 0.5);
    left: 268px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#SITE_HEADER {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --shc-mutated-brightness: 126, 126, 126;
    position: sticky !important;
    top: var(--wix-ads-height);
}

#comp-jy05jif9 {
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingTop: 0px;
    --contentPaddingBottom: 0px;
}

#comp-jy05jif9 {
    width: 250px;
    height: 50px;
}

#comp-jy05jif9 {
    --height: 50px;
    --width: 250px;
}

#comp-isyhm8wh {
    --menuTotalBordersX: 0px;
    --menuTotalBordersY: 0px;
    --bgDrop: var(--color_11);
    --rd: 0px;
    --shd: none;
    --fnt: normal normal normal 17px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --pad: 5px;
    --txt: 0 , 0, 0 ;
    --trans: color 0.4s ease 0s;
    --txth: 168, 85, 81;
    --txts: 166, 194, 227;
    --alpha-txth: 1;
    --alpha-txts: 1;
    --alpha-bgDrop: 1;
    --boxShadowToggleOn-shd: none;
    --alpha-txt: 1;
}

#comp-isyhm8wh {
    width: 694px;
    height: 50px;
}

#comp-isyhm8wh {
    --menuTotalBordersY: 0px;
    --menuTotalBordersX: 0px;
}

#PAGES_CONTAINER {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

#SITE_PAGES {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

#SITE_PAGES {
    --transition-duration: 700ms;
}

#SITE_FOOTER {
    --bg: 34,139,34;
    --shd: none;
    --brwt: 0px;
    --brd: var(--color_11);
    --brwb: 0px;
    --bgctr: var(--color_25);
    --rd: 0px;
    --alpha-bg: 1;
    --alpha-bgctr: 0;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#SITE_FOOTER {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=SITE_FOOTERinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jxgb2sr3"] {
    position: relative;
    margin: 45px 0px 35px calc((100% - 980px) * 0.5);
    left: 26px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jxm1t9ot"] {
    position: relative;
    margin: 102px 0px 10px calc((100% - 980px) * 0.5);
    left: 457px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jxm1pdj1"] {
    position: relative;
    margin: 102px 0px 10px calc((100% - 980px) * 0.5);
    left: 534px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-isykdffh"] {
    position: relative;
    margin: 102px 0px 10px calc((100% - 980px) * 0.5);
    left: 672px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jxm1p3a6"] {
    position: relative;
    margin: 102px 0px 10px calc((100% - 980px) * 0.5);
    left: 748px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jy058dz9"] {
    position: relative;
    margin: 23px 0px 19px calc((100% - 980px) * 0.5);
    left: 850px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-jzk3mqyl"] {
    position: relative;
    margin: 102px 0px 10px calc((100% - 980px) * 0.5);
    left: 850px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=SITE_FOOTERinlineContent-gridContainer]>[id="comp-isyk3ta2"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#SITE_FOOTER {
    --shc-mutated-brightness: 25, 32, 40;
    --bg-overlay-color: transparent;
    --bg-gradient: none;
}

#comp-jxgb2sr3 {
    width: 203px;
    height: 146px;
}

#comp-jxgb2sr3 {
    --opacity: 1;
}

#comp-jxm1t9ot {
    width: 65px;
    height: auto;
}

#comp-jxm1pdj1 {
    width: 124px;
    height: auto;
}

#comp-isykdffh {
    width: 76px;
    height: auto;
}

#comp-jxm1p3a6 {
    width: 93px;
    height: auto;
}

#comp-jy058dz9 {
    width: 190px;
    height: 184px;
}

#comp-jy058dz9 {
    --opacity: 0.1;
}

#comp-jzk3mqyl {
    width: 94px;
    height: auto;
}

#comp-isyk3ta2 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-isyk3tlwinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-isyk3tlwinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-isyk3tlwinlineContent-gridContainer]>[id="comp-isykf1rb"] {
    position: relative;
    margin: 27px 0px 27px calc((100% - 980px) * 0.5);
    left: 200px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-isyk3ta2 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-isyk3tlw {
    width: 980px;
}

#comp-isyk3tlw {
    --bg-overlay-color: rgb(var(--color_24));
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-isykf1rb {
    width: 637px;
    height: auto;
}


/*------------------------*/

main p .wixui-rich-text__text{
    color: rgb(49, 63, 79);

}
footer div h6 span .wixui-rich-text__text{
    font-size: 18px ;



}
footer div h6{
    text-align: center;

}
header div{
    background-color: #fffff0;
    /*background-color: #333;*/
}

#comp-jxg5n3t6 {
    --bg-overlay-color: #228B22;
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

.aVng1S {
    border-top: var(--lnw, 2px) solid rgba(194, 178, 128, var(--alpha-brd, 1));
    box-sizing: border-box;
    height: 0
}


/* Container for the icons */
.icon-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Adjusted to accommodate 5 icons */
    gap: 20px; /* Space between icons */
    justify-items: center; /* Center icons horizontally within their grid cells */
    align-items: center; /* Center icons vertically */
    padding: 20px 0; /* Add some padding */
    width: 70%; /* Set width to 70% */
    margin: 0 auto; /* Center the container horizontally */
    box-sizing: border-box;
}

/* Individual icon boxes */
.icon-box {
    display: flex;
    flex-direction: column; /* Stack icon and text */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center the text */
    font-family: 'Libre Baskerville', serif; /* Set the desired font */
    font-size: 16px;
    color: black; /* Set text color */
}

/* The actual SVG icon */
.icon-box img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px; /* Space between icon and text */
}

/* Icon text styling */
.icon-text {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.01em;

}



.aspect{

    height: 500px; /* Adjust this height as needed */
    position: relative; /* Add this if not already present */
    z-index: 1;

}


.header-logo{width: 250px; height: 50px;}



/* Mobile Styles (for screens with a maximum width of 768px) */
/* Base styles for the header and navbar */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

/* Hide the navbar by default on all screens */
.navbar {
    display: none;
}

/* Show the menu toggle button only on mobile screens */
.menu-toggle {
    display: none; /* Hidden by default */
}

