
@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWx8QDO-WyrubOA.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWxEQDO-Wyrs.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMaC82U-ro.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxMaC82U.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY5KcC-wLOjAUw.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("http://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcC-wLOj.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W01-Reg";
    src: url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"), url("http://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W05-Reg";
    src: url("http://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff2") format("woff2"),
    url("http://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff") format("woff");
    font-display: swap;
}

#zm2x4 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

#masterPage {
    --pinned-layers-in-page: 0;
}

[data-mesh-id=Containerzm2x4inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=Containerzm2x4inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 2732px;
    grid-template-rows: repeat(3, min-content) 1fr;
    grid-template-columns: 100%;
    padding-bottom: 0px;
    box-sizing: border-box;
}

[data-mesh-id=Containerzm2x4inlineContent-gridContainer]>[id="comp-lh3d5tq2"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerzm2x4inlineContent-gridContainer]>[id="comp-lh3d5tq21"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerzm2x4inlineContent-gridContainer]>[id="comp-lh3d5tq3"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerzm2x4inlineContent-gridContainer]>[id="comp-lh3d5tq31"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

#comp-lh3d5tq2 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tq2inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tq2inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tq2inlineContent-gridContainer]>[id="comp-jxly1qlb"] {
    position: relative;
    margin: 21px 0px -16px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3d5tq2inlineContent-gridContainer]>[id="comp-jxlyt9t5"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxly1qlb {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxly1qmiinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxly1qmiinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxly1qmiinlineContent-gridContainer]>[id="comp-jzxg2t9l"] {
    position: relative;
    margin: 72px 0px 63px calc((100% - 980px) * 0.5);
    left: 28px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxly1qmiinlineContent-gridContainer]>[id="comp-jxly1qn0"] {
    position: relative;
    margin: 0px 0px 64px calc((100% - 980px) * 0.5);
    left: 160px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxly1qmi {
    width: 980px;
}



#comp-jzxg2t9l {
    width: 943px;
}

[data-mesh-id=comp-jzxg2t9linlineContent] {
    height: auto;
    width: 943px;
}

[data-mesh-id=comp-jzxg2t9linlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxg2t9linlineContent-gridContainer]>[id="comp-jxm13x6d"] {
    position: relative;
    margin: 0px 0px 2px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg2t9linlineContent-gridContainer]>[id="comp-jxm1fbv3"] {
    position: relative;
    margin: 0px 0px 11px 0;
    left: 4px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg2t9linlineContent-gridContainer]>[id="comp-jxly1qmx"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 3px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm13x6d {
    width: 675px;
    height: auto;
}

#comp-jxm1fbv3 {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jxm1fbv3 {
    width: 86px;
    height: 5px;
}

#comp-jxly1qmx {
    width: 940px;
    height: auto;
}

#comp-jxly1qn0 {
    width: 660px;
    height: 504px;
}

#comp-jxlyt9t5 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxlyt9u9inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 584px;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer]>[id="comp-jzxg449j"] {
    position: relative;
    margin: 101px 0px 52px calc((100% - 980px) * 0.5);
    left: 28px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer]>[id="comp-jxlyudcj"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 14px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer]>[id="comp-jxlyugh5"] {
    position: relative;
    margin: 15px 0px 10px calc((100% - 980px) * 0.5);
    left: 251px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer]>[id="comp-jxlyuhnj"] {
    position: relative;
    margin: 15px 0px 10px calc((100% - 980px) * 0.5);
    left: 487px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyt9u9inlineContent-gridContainer]>[id="comp-jxlyuf5u"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 743px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxlyt9u9 {
    width: 980px;
}


#comp-jzxg449j {
    width: 943px;
}

[data-mesh-id=comp-jzxg449jinlineContent] {
    height: auto;
    width: 943px;
}

/*[data-mesh-id=comp-jzxg449jinlineContent-gridContainer] {*/
/*    position: static;*/
/*    display: grid;*/
/*    height: auto;*/
/*    width: 100%;*/
/*    min-height: auto;*/
/*    grid-template-rows: repeat(2, min-content) 1fr;*/
/*    grid-template-columns: 100%;*/
/*}*/

[data-mesh-id=comp-jzxg449jinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: repeat(5, 1fr); /* Adjusted to accommodate 5 items */
    grid-gap: 20px; /* Optional: Add space between the icons */
    justify-items: center; /* Centers icons horizontally */
    align-items: center; /* Aligns icons vertically */
}


[data-mesh-id=comp-jzxg449jinlineContent-gridContainer]>[id="comp-jxm12y8b"] {
    position: relative;
    margin: 0px 0px 4px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg449jinlineContent-gridContainer]>[id="comp-jxm1j9w1"] {
    position: relative;
    margin: 0px 0px 11px 0;
    left: 4px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg449jinlineContent-gridContainer]>[id="comp-jxlytjgw"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 3px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}



#comp-jxm12y8b {
    width: 675px;
    height: auto;
}

#comp-jxm1j9w1 {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jxm1j9w1 {
    width: 98px;
    height: 5px;
}

#comp-jxlytjgw {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxlytjgw {
    width: 940px;
    height: auto;
}


#comp-jxlyudcj {
    width: 210px;
}

[data-mesh-id=comp-jxlyudcjinlineContent] {
    height: auto;
    width: 210px;
}

[data-mesh-id=comp-jxlyudcjinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxlyudcjinlineContent-gridContainer]>[id="comp-jxlytjej"] {
    position: relative;
    margin: 0px 0px 21px 0;
    left: 78px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyudcjinlineContent-gridContainer]>[id="comp-jxlytje4"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxlytjej {
    width: 54px;
    height: 61px;
}

#comp-jxlytje4 {
    width: 210px;
    height: auto;
}


#comp-jxlyugh5 {
    width: 210px;
}

[data-mesh-id=comp-jxlyugh5inlineContent] {
    height: auto;
    width: 210px;
}

[data-mesh-id=comp-jxlyugh5inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxlyugh5inlineContent-gridContainer]>[id="comp-jxlytjdm"] {
    position: relative;
    margin: 0px 0px 24px 0;
    left: 71px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyugh5inlineContent-gridContainer]>[id="comp-jxlytjd7"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxlytjdm {
    width: 62px;
    height: 45px;
}

#comp-jxlytjd7 {
    width: 210px;
    height: auto;
}



#comp-jxlyuhnj {
    width: 216px;
}

[data-mesh-id=comp-jxlyuhnjinlineContent] {
    height: auto;
    width: 216px;
}

[data-mesh-id=comp-jxlyuhnjinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxlyuhnjinlineContent-gridContainer]>[id="comp-jxlytjbx"] {
    position: relative;
    margin: 0px 0px 45px 0;
    left: 51px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyuhnjinlineContent-gridContainer]>[id="comp-jxlytjbd"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxlytjbx {
    width: 116px;
    height: 34px;
}

#comp-jxlytjbd {
    width: 216px;
    height: auto;
}



#comp-jxlyuf5u {
    width: 216px;
}

[data-mesh-id=comp-jxlyuf5uinlineContent] {
    height: auto;
    width: 216px;
}

[data-mesh-id=comp-jxlyuf5uinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxlyuf5uinlineContent-gridContainer]>[id="comp-jxlytjcs"] {
    position: relative;
    margin: 0px 0px 32px 0;
    left: 70px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxlyuf5uinlineContent-gridContainer]>[id="comp-jxlytjcd"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxlytjcs {
    width: 68px;
    height: 62px;
}

#comp-jxlytjcd {
    width: 216px;
    height: auto;
}

#comp-lh3d5tq21 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tq21inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tq21inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tq21inlineContent-gridContainer]>[id="comp-jy04tfje"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy04tfje {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jy04tfnuinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jy04tfnuinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 800px;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jy04tfnuinlineContent-gridContainer]>[id="comp-jzxg77gt"] {
    position: relative;
    margin: 64px 0px 10px calc((100% - 980px) * 0.5);
    left: 32px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jy04tfnuinlineContent-gridContainer]>[id="comp-jy04xk0m"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 158px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy04tfnu {
    width: 980px;
}



#comp-jzxg77gt {
    width: 909px;
}

[data-mesh-id=comp-jzxg77gtinlineContent] {
    height: auto;
    width: 909px;
}

[data-mesh-id=comp-jzxg77gtinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxg77gtinlineContent-gridContainer]>[id="comp-jy04xtt0"] {
    position: relative;
    margin: 0px 0px 14px 0;
    left: 1px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg77gtinlineContent-gridContainer]>[id="comp-jy04xtrm"] {
    position: relative;
    margin: 0px 0px -1px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg77gtinlineContent-gridContainer]>[id="comp-jy04xtsd"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 3px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy04xtt0 {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jy04xtt0 {
    width: 130px;
    height: 5px;
}

#comp-jy04xtrm {
    width: 675px;
    height: auto;
}

#comp-jy04xtsd {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jy04xtsd {
    width: 906px;
    height: auto;
}



#comp-jy04xk0m {
    width: 655px;
    height: 405px;
}

#comp-lh3d5tq3 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tq3inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tq3inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tq3inlineContent-gridContainer]>[id="comp-jxm15pfs"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm15pfs {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxm15pgminlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxm15pgminlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 839px;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxm15pgminlineContent-gridContainer]>[id="comp-jzxg7pda"] {
    position: relative;
    margin: 88px 0px 48px calc((100% - 980px) * 0.5);
    left: 26px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxm15pgminlineContent-gridContainer]>[id="comp-jy07uw87"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 238px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm15pgm {
    width: 980px;
}



#comp-jzxg7pda {
    width: 911px;
}

[data-mesh-id=comp-jzxg7pdainlineContent] {
    height: auto;
    width: 911px;
}

[data-mesh-id=comp-jzxg7pdainlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxg7pdainlineContent-gridContainer]>[id="comp-jxm15pgq"] {
    position: relative;
    margin: 0px 0px 2px 0;
    left: 2px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg7pdainlineContent-gridContainer]>[id="comp-jxm1eyb7"] {
    position: relative;
    margin: 0px 0px 11px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxg7pdainlineContent-gridContainer]>[id="comp-jxm15pgs"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 5px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm15pgq {
    width: 675px;
    height: auto;
}

#comp-jxm1eyb7 {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jxm1eyb7 {
    width: 86px;
    height: 5px;
}

#comp-jxm15pgs {
    width: 906px;
    height: auto;
}


#comp-jy07uw87 {
    width: 491px;
    height: 484px;
}

#comp-lh3d5tq31 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tq31inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tq31inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tq31inlineContent-gridContainer]>[id="comp-jxm333u5"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm333u5 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxm333ydinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxm333ydinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxm333ydinlineContent-gridContainer]>[id="comp-jxmaiv0z"] {
    position: relative;
    margin: 41px 0px 42px calc((100% - 980px) * 0.5);
    left: -30px;
    grid-area: 1 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxm333ydinlineContent-gridContainer]>[id="comp-jxmajf99"] {
    position: relative;
    margin: 113px 0px 45px calc((100% - 980px) * 0.5);
    left: 28px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxm333ydinlineContent-gridContainer]>[id="comp-jxmajfa0"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 358px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxm333yd {
    width: 980px;
}

#comp-jxmaiv0z {
    width: 333px;
    height: 322px;
}

#comp-jxmajf99 {
    width: 946px;
    height: auto;
}

#comp-jxmajfa0 {
    --rd: 0px;
    --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    --shd: none;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --trans2: color 0.4s ease 0s;
    --txt: 49, 63, 79;
    --brw: 0px;
    --bg: 255, 255, 255;
    --brd: var(--color_11);
    --bgh: var(--color_17);
    --brdh: var(--color_12);
    --txth: var(--color_11);
    --bgd: 204, 204, 204;
    --alpha-bgd: 1;
    --brdd: 204, 204, 204;
    --alpha-brdd: 1;
    --txtd: 255, 255, 255;
    --alpha-txtd: 1;
    --alpha-bg: 1;
    --alpha-bgh: 1;
    --alpha-brd: 0;
    --alpha-brdh: 0;
    --alpha-txt: 1;
    --alpha-txth: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxmajfa0 {
    width: 301px;
    height: 69px;
}

#zm2x4 {
    width: auto;
    min-height: 2732px;
}

#pageBackground_zm2x4 {
    --bg-position: absolute;
    --bg-overlay-color: rgb(var(--color_11));
    --bg-gradient: none;
}

[class^="backgroundGroup"] :not(#pageBackground_zm2x4) {
    --bg-position: absolute;
    --bg-overlay-color: rgb(var(--color_11));
    --bg-gradient: none;
}

#comp-lh3d5tq2 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxly1qlb {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxly1qmi {
    --bg-overlay-color: rgb(255, 255, 255);
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxm1fbv3 {
    transform-origin: center 1.5px;
}

#comp-jxly1qn0 {
    --opacity: 1;
}

#comp-jxlyt9t5 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxlyt9u9 {
    --bg-overlay-color: rgb(251, 251, 251);
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxm1j9w1 {
    transform-origin: center 1.5px;
}

#comp-jxlytjej {
    --opacity: 1;
}

#comp-jxlytjdm {
    --opacity: 1;
}

#comp-jxlytjbx {
    --opacity: 1;
}

#comp-jxlytjcs {
    --opacity: 1;
}

#comp-lh3d5tq21 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jy04tfje {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jy04tfnu {
    --bg-overlay-color: rgb(var(--color_11));
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jy04xtt0 {
    transform-origin: center 1.5px;
}

#comp-jy04xk0m {
    --opacity: 1;
}

#comp-lh3d5tq3 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxm15pfs {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxm15pgm {
    --bg-overlay-color: rgb(251, 251, 251);
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxm1eyb7 {
    transform-origin: center 1.5px;
}

#comp-jy07uw87 {
    --opacity: 1;
}

#comp-lh3d5tq31 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxm333u5 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxm333yd {
    --bg-overlay-color: #C2B280;
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxmaiv0z {
    --opacity: 0.5;
}

#comp-jxmajfa0 {
    --shc-mutated-brightness: 128, 128, 128;
    --margin-start: 0px;
    --margin-end: 0px;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --label-align: center;
    --label-text-align: center;
}

