
@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWx8QDO-WyrubOA.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcWxEQDO-Wyrs.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMaC82U-ro.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 400;
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxMaC82U.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY5KcC-wLOjAUw.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: normal;
    font-weight: 700;
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/librebaskerville/v7/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTgY3KcC-wLOj.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Light";
    src: url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v14/2hXzmNaFRuKTSBR9nRGO-A.woff2") format("woff2"),
    url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff/s/lato/v14/kU6VHbqMAZhaN_nXCmLQsQ.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Lato-Light";
    font-weight: 700;
    src: url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v14/7nLfsQCzhQW_PwpkrwroYw.woff2") format("woff2"),
    url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff/s/lato/v14/I1Pn3gihk5vyP0Yw5GqKsQ.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Lato-Light";
    font-style: italic;
    src: url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v14/XNVd6tsqi9wmKNvnh5HNEFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff/s/lato/v14/XNVd6tsqi9wmKNvnh5HNED8E0i7KZn-EPnyo3HZu7kw.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Lato-Light";
    font-weight: 700;
    font-style: italic;
    src: url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v14/AcvTq8Q0lyKKNxRlL28Rn1tXRa8TVwTICgirnJhmVJw.woff2") format("woff2"),
    url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff/s/lato/v14/AcvTq8Q0lyKKNxRlL28Rnz8E0i7KZn-EPnyo3HZu7kw.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url("https://static.parastorage.com/tag-bundler/api/v1/fonts-cache/googlefont/woff2/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W01-Reg";
    src: url("https://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-N-W05-Reg";
    src: url("https://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff2") format("woff2"),
    url("https://static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/ProximNova/v1/ProximaNovaW05-Regular.woff") format("woff");
    font-display: swap;
}

#bzld2 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

#masterPage {
    --pinned-layers-in-page: 0;
}

[data-mesh-id=Containerbzld2inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 40px;
    grid-template-rows: repeat(4, min-content) 1fr;
    grid-template-columns: 100%;
    padding-bottom: 0px;
    box-sizing: border-box;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer]>[id="comp-lh3d5tpq"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer]>[id="comp-lh3d5tpr"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer]>[id="comp-lh3d5tpr1"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer]>[id="comp-lh3d5tpt"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=Containerbzld2inlineContent-gridContainer]>[id="comp-lh3d5tpu"] {
    position: relative;
    margin: 0px 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

#comp-lh3d5tpq {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tpqinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tpqinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tpqinlineContent-gridContainer]>[id="comp-jxmbaamv"] {
    position: relative;
    margin: 0px 0px 1px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmbaamv {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxmbaaovinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxmbaaovinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxmbaaovinlineContent-gridContainer]>[id="comp-jxmbalwe"] {
    position: relative;
    margin: 92px 0px 35px calc((100% - 980px) * 0.5);
    left: 50px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxmbaaovinlineContent-gridContainer]>[id="comp-jxmc9hwq"] {
    position: relative;
    margin: 41px 0px 31px calc((100% - 980px) * 0.5);
    left: 769px;
    grid-area: 1 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxmbaaovinlineContent-gridContainer]>[id="comp-jxmbalxa"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 56px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmbaaov {
    width: 980px;
}

#comp-jxmbalwe {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxmbalwe:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxmbalwe {
    width: 878px;
    height: auto;
}

#comp-jxmc9hwq {
    width: 333px;
    height: 321px;
}

#comp-jxmbalxa {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxmbalxa:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxmbalxa {
    width: 42px;
    height: 5px;
}

#comp-lh3d5tpr {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tprinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tprinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tprinlineContent-gridContainer]>[id="comp-jy05446j"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy05446j {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jy05447einlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jy05447einlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 561px;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jy05447einlineContent-gridContainer]>[id="comp-jzxgaoq8"] {
    position: relative;
    margin: 137px 0px 10px calc((100% - 980px) * 0.5);
    left: 28px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy05447e {
    width: 980px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgaoq8:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgaoq8 {
    width: 943px;
}

[data-mesh-id=comp-jzxgaoq8inlineContent] {
    height: auto;
    width: 943px;
}

[data-mesh-id=comp-jzxgaoq8inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgaoq8inlineContent-gridContainer]>[id="comp-jy05447i"] {
    position: relative;
    margin: 0px 0px 61px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgaoq8inlineContent-gridContainer]>[id="comp-jy05447o"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 3px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jy05447i {
    width: 860px;
    height: auto;
}

#comp-jy05447o {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jy05447o {
    width: 940px;
    height: auto;
}

#comp-lh3d5tpr1 {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tpr1inlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tpr1inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tpr1inlineContent-gridContainer]>[id="comp-jzxgbf2y"] {
    position: relative;
    margin: 72px 0px 32px calc((100% - 980px) * 0.5);
    left: 38px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgbf2y:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgbf2y {
    width: 893px;
}

[data-mesh-id=comp-jzxgbf2yinlineContent] {
    height: auto;
    width: 893px;
}

[data-mesh-id=comp-jzxgbf2yinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgbf2yinlineContent-gridContainer]>[id="comp-jxmb6x7j"] {
    position: relative;
    margin: 0px 0px 2px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbf2yinlineContent-gridContainer]>[id="comp-jzd5hjtn"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 283px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmb6x7j {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxmb6x7j {
    width: 893px;
    height: auto;
}

#comp-jzd5hjtn {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jzd5hjtn {
    width: 329px;
    height: 5px;
}

#comp-lh3d5tpt {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tptinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-lh3d5tptinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 628px;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tptinlineContent-gridContainer]>[id="comp-jzxgbooa"] {
    position: relative;
    margin: 33px 0px 10px calc((100% - 980px) * 0.5);
    left: 40px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3d5tptinlineContent-gridContainer]>[id="comp-jzxgbsil"] {
    position: relative;
    margin: 44px 0px 10px calc((100% - 980px) * 0.5);
    left: 346px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3d5tptinlineContent-gridContainer]>[id="comp-l256u5ap"] {
    position: relative;
    margin: 42px 0px 10px calc((100% - 980px) * 0.5);
    left: 672px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgbooa:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgbooa {
    width: 289px;
}

[data-mesh-id=comp-jzxgbooainlineContent] {
    height: auto;
    width: 289px;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-jxmamedc"] {
    position: relative;
    margin: 0px 0px 22px 0;
    left: 40px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-jxgk29bq"] {
    position: relative;
    margin: 0px 0px 17px 0;
    left: 10px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-iwxdvdn5"] {
    position: relative;
    margin: 0px 0px 19px 0;
    left: 10px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-iwxdvdn4"] {
    position: relative;
    margin: 0px 0px 53px 0;
    left: 10px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-jzitf39f"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbooainlineContent-gridContainer]>[id="comp-itwyomte"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 122px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmamedc {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxmamedc {
    width: 214px;
    height: 217px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgk29bq:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgk29bq {
    width: 279px;
    height: auto;
}

#comp-iwxdvdn5 {
    width: 279px;
    height: auto;
}

#comp-iwxdvdn4 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-iwxdvdn4 {
    width: 279px;
    height: auto;
}

#comp-jzitf39f {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitf39f {
    width: 279px;
    height: auto;
}

#comp-itwyomte {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgbsil:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgbsil {
    width: 281px;
}

[data-mesh-id=comp-jzxgbsilinlineContent] {
    height: auto;
    width: 281px;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jxmamecg"] {
    position: relative;
    margin: 0px 0px 25px 0;
    left: 32px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jxgk4jwy"] {
    position: relative;
    margin: 0px 0px 14px 0;
    left: 2px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jxgk4jx1"] {
    position: relative;
    margin: 0px 0px 19px 0;
    left: 2px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jxgk4jx4"] {
    position: relative;
    margin: 0px 0px 42px 0;
    left: 2px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jzitdy8c"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgbsilinlineContent-gridContainer]>[id="comp-jxgk4jx6"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 131px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmamecg {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxmamecg {
    width: 214px;
    height: 217px;
}

#comp-jxgk4jwy {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgk4jwy:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgk4jwy {
    width: 279px;
    height: auto;
}

#comp-jxgk4jx1 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxgk4jx1 {
    width: 279px;
    height: auto;
}

#comp-jxgk4jx4 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxgk4jx4 {
    width: 279px;
    height: auto;
}

#comp-jzitdy8c {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitdy8c {
    width: 279px;
    height: auto;
}

#comp-jxgk4jx6 {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-l256u5ap:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-l256u5ap {
    width: 284px;
}

[data-mesh-id=comp-l256u5apinlineContent] {
    height: auto;
    width: 284px;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5bo"] {
    position: relative;
    margin: 0px 0px 25px 0;
    left: 35px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5c4"] {
    position: relative;
    margin: 0px 0px 14px 0;
    left: 5px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5cb"] {
    position: relative;
    margin: 0px 0px 19px 0;
    left: 5px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5cc2"] {
    position: relative;
    margin: 0px 0px 23px 0;
    left: 5px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5ce"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-l256u5apinlineContent-gridContainer]>[id="comp-l256u5cl"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 133px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-l256u5bo {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-l256u5bo {
    width: 214px;
    height: 217px;
}

#comp-l256u5c4 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-l256u5c4:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-l256u5c4 {
    width: 279px;
    height: auto;
}

#comp-l256u5cb {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-l256u5cb {
    width: 279px;
    height: auto;
}

#comp-l256u5cc2 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-l256u5cc2 {
    width: 279px;
    height: auto;
}

#comp-l256u5ce {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-l256u5ce {
    width: 279px;
    height: auto;
}

#comp-l256u5cl {
    width: 22px;
    height: 22px;
}

#comp-lh3d5tpu {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 0;
}

[data-mesh-id=comp-lh3d5tpuinlineContent] {
    height: auto;
    width: 100%;
    display: flex;
}

[data-mesh-id=comp-lh3d5tpuinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    margin-bottom: -92px;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3d5tpuinlineContent-gridContainer]>[id="comp-jxgkdrcn"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3d5tpuinlineContent-gridContainer]>[id="comp-jxmal10r"] {
    position: relative;
    margin: 0px 0px 0px calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgkdrcn {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxgkdretinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: 2254px;
    grid-template-rows: repeat(4, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgdzxy"] {
    position: relative;
    margin: 105px 0px 57px calc((100% - 980px) * 0.5);
    left: 40px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-liage1rj"] {
    position: relative;
    margin: 0px 0px 56px calc((100% - 980px) * 0.5);
    left: 33px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jxgky1bm"] {
    position: relative;
    margin: 594px 0px 64px calc((100% - 980px) * 0.5);
    left: 163px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgg5bn"] {
    position: relative;
    margin: 0px 0px 63px calc((100% - 980px) * 0.5);
    left: 359px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgf3ay"] {
    position: relative;
    margin: 0px 0px 62px calc((100% - 980px) * 0.5);
    left: 691px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-lh3dlsi3"] {
    position: relative;
    margin: 11px 0px 60px calc((100% - 980px) * 0.5);
    left: 23px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgf9ed"] {
    position: relative;
    margin: 6px 0px 61px calc((100% - 980px) * 0.5);
    left: 368px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgey69"] {
    position: relative;
    margin: 0px 0px 61px calc((100% - 980px) * 0.5);
    left: 671px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-jzxgejac"] {
    position: relative;
    margin: 0px 0px -18px calc((100% - 980px) * 0.5);
    left: 351px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxgkdretinlineContent-gridContainer]>[id="comp-lidzsj9g"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 358px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgkdret {
    width: 980px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgdzxy:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgdzxy {
    width: 892px;
}

[data-mesh-id=comp-jzxgdzxyinlineContent] {
    height: auto;
    width: 892px;
}

[data-mesh-id=comp-jzxgdzxyinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgdzxyinlineContent-gridContainer]>[id="comp-jxmax67q"] {
    position: relative;
    margin: 0px 0px 2px 0;
    left: 0px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgdzxyinlineContent-gridContainer]>[id="comp-jzd5igep"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 48px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmax67q {
    width: 892px;
    height: auto;
}

#comp-jzd5igep {
    --lnw: 3px;
    --brd: 166, 194, 227;
    --alpha-brd: 1;
}

#comp-jzd5igep {
    width: 799px;
    height: 5px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-liage1rj:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-liage1rj {
    width: 280px;
}

[data-mesh-id=comp-liage1rjinlineContent] {
    height: auto;
    width: 280px;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(4, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer]>[id="comp-jxgky17m"] {
    position: relative;
    margin: 0px 0px 32px 0;
    left: 34px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer]>[id="comp-jxgky1ba"] {
    position: relative;
    margin: 0px 0px 19px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer]>[id="comp-jxgky1bc"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer]>[id="comp-jxgky1bf"] {
    position: relative;
    margin: 0px 0px 14px 0;
    left: 1px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-liage1rjinlineContent-gridContainer]>[id="comp-jzitiabq"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgky17m {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgky17m {
    width: 214px;
    height: 217px;
}

#comp-jxgky1ba {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgky1ba:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgky1ba {
    width: 279px;
    height: auto;
}

#comp-jxgky1bc {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxgky1bc {
    width: 279px;
    height: auto;
}

#comp-jxgky1bf {
    width: 279px;
    height: auto;
}

#comp-jzitiabq {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitiabq {
    width: 279px;
    height: auto;
}

#comp-jxgky1bm {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgg5bn:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgg5bn {
    width: 280px;
}

[data-mesh-id=comp-jzxgg5bninlineContent] {
    height: auto;
    width: 280px;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jxgky172"] {
    position: relative;
    margin: 0px 0px 32px 0;
    left: 33px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jxgky1c4"] {
    position: relative;
    margin: 0px 0px 21px 0;
    left: 1px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jxgky1c7"] {
    position: relative;
    margin: 0px 0px 26px 0;
    left: 1px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jxgky1c9"] {
    position: relative;
    margin: 0px 0px 31px 0;
    left: 1px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jzitigxq"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgg5bninlineContent-gridContainer]>[id="comp-jxgky1cc"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 128px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgky172 {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgky172 {
    width: 214px;
    height: 217px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgky1c4:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgky1c4 {
    width: 279px;
    height: auto;
}

#comp-jxgky1c7 {
    width: 279px;
    height: auto;
}

#comp-jxgky1c9 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxgky1c9 {
    width: 279px;
    height: auto;
}

#comp-jzitigxq {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitigxq {
    width: 279px;
    height: auto;
}

#comp-jxgky1cc {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgf3ay:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgf3ay {
    width: 290px;
}

[data-mesh-id=comp-jzxgf3ayinlineContent] {
    height: auto;
    width: 290px;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jxgkob6d"] {
    position: relative;
    margin: 0px 0px 32px 0;
    left: 32px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jxgkeo9v"] {
    position: relative;
    margin: 0px 0px 12px 0;
    left: 9px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jxgkeo9y"] {
    position: relative;
    margin: 0px 0px 15px 0;
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jxgkeoa0"] {
    position: relative;
    margin: 0px 0px 66px 0;
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jzith4a6"] {
    position: relative;
    margin: 0px 0px 19px 0;
    left: 11px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf3ayinlineContent-gridContainer]>[id="comp-jxgkeoa2"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 135px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgkob6d {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgkob6d {
    width: 214px;
    height: 217px;
}

#comp-jxgkeo9v {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgkeo9v:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgkeo9v {
    width: 279px;
    height: auto;
}

#comp-jxgkeo9y {
    width: 279px;
    height: auto;
}

#comp-jxgkeoa0 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jxgkeoa0 {
    width: 279px;
    height: auto;
}

#comp-jzith4a6 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzith4a6 {
    width: 279px;
    height: auto;
}

#comp-jxgkeoa2 {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-lh3dlsi3:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-lh3dlsi3 {
    width: 301px;
}

[data-mesh-id=comp-lh3dlsi3inlineContent] {
    height: auto;
    width: 301px;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsjx"] {
    position: relative;
    margin: 0px 0px 30px 0;
    left: 34px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsjy3"] {
    position: relative;
    margin: 0px 0px 17px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsk0"] {
    position: relative;
    margin: 0px 0px 22px 0;
    left: 1px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsk1"] {
    position: relative;
    margin: 0px 0px 11px 0;
    left: 1px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsk21"] {
    position: relative;
    margin: 0px 0px 34px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-lh3dlsi3inlineContent-gridContainer]>[id="comp-lh3dlsk31"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 129px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-lh3dlsjx {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-lh3dlsjx {
    width: 214px;
    height: 217px;
}

#comp-lh3dlsjy3 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-lh3dlsjy3:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-lh3dlsjy3 {
    width: 279px;
    height: auto;
}

#comp-lh3dlsk0 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-lh3dlsk0 {
    width: 279px;
    height: auto;
}

#comp-lh3dlsk1 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-lh3dlsk1 {
    width: 300px;
    height: auto;
}

#comp-lh3dlsk21 {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-lh3dlsk21 {
    width: 279px;
    height: auto;
}

#comp-lh3dlsk31 {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgf9ed:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgf9ed {
    width: 294px;
}

[data-mesh-id=comp-jzxgf9edinlineContent] {
    height: auto;
    width: 294px;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jxgky186"] {
    position: relative;
    margin: 0px 0px 23px 0;
    left: 35px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jxgky1ai"] {
    position: relative;
    margin: 0px 0px 26px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jxgky1am"] {
    position: relative;
    margin: 0px 0px 18px 0;
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jxgky1ap"] {
    position: relative;
    margin: 0px 0px 56px 0;
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jzithtbx"] {
    position: relative;
    margin: 0px 0px 34px 0;
    left: 2px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgf9edinlineContent-gridContainer]>[id="comp-jxgky1as"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 137px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgky186 {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgky186 {
    width: 224px;
    height: 224px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgky1ai:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgky1ai {
    width: 292px;
    height: auto;
}

#comp-jxgky1am {
    width: 292px;
    height: auto;
}

#comp-jxgky1ap {
    width: 292px;
    height: auto;
}

#comp-jzithtbx {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzithtbx {
    width: 292px;
    height: auto;
}

#comp-jxgky1as {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgey69:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgey69 {
    width: 316px;
}

[data-mesh-id=comp-jzxgey69inlineContent] {
    height: auto;
    width: 316px;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jxgkeo48"] {
    position: relative;
    margin: 0px 0px 49px 0;
    left: 35px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jxgkeo8d"] {
    position: relative;
    margin: 0px 0px 21px 0;
    left: 9px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jxgkeo8g"] {
    position: relative;
    margin: 0px 0px 20px 0;
    left: 17px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jxgkeo8i"] {
    position: relative;
    margin: 0px 0px 40px 0;
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jzitgz9p"] {
    position: relative;
    margin: 0px 0px 38px 0;
    left: 0px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgey69inlineContent-gridContainer]>[id="comp-jxgkeo8k"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 148px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgkeo48 {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgkeo48 {
    width: 220px;
    height: 220px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgkeo8d:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgkeo8d {
    width: 299px;
    height: auto;
}

#comp-jxgkeo8g {
    width: 299px;
    height: auto;
}

#comp-jxgkeo8i {
    width: 299px;
    height: auto;
}

#comp-jzitgz9p {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitgz9p {
    width: 299px;
    height: auto;
}

#comp-jxgkeo8k {
    width: 22px;
    height: 22px;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jzxgejac:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jzxgejac {
    width: 280px;
}

[data-mesh-id=comp-jzxgejacinlineContent] {
    height: auto;
    width: 280px;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(5, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jxgko7ig"] {
    position: relative;
    margin: 0px 0px 22px 0;
    left: 33px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jxgkeo6x"] {
    position: relative;
    margin: 0px 0px 21px 0;
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jxgkeo70"] {
    position: relative;
    margin: 0px 0px 15px 0;
    left: 0px;
    grid-area: 3 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jxgkeo72"] {
    position: relative;
    margin: 0px 0px 21px 0;
    left: 0px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jzitgs7f"] {
    position: relative;
    margin: 0px 0px 16px 0;
    left: 1px;
    grid-area: 5 / 1 / 6 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jzxgejacinlineContent-gridContainer]>[id="comp-jxgkeo74"] {
    position: relative;
    margin: 0px 0px 0px 0;
    left: 130px;
    grid-area: 6 / 1 / 7 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxgko7ig {
    --shd: none;
    --brw: 0px;
    --brd: 255, 255, 255;
    --contentPaddingLeft: 0px;
    --contentPaddingRight: 0px;
    --contentPaddingBottom: 0px;
    --contentPaddingTop: 0px;
    --alpha-brd: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxgko7ig {
    width: 214px;
    height: 217px;
}

#comp-jxgkeo6x {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

@media (prefers-reduced-motion: no-preference) {
    #comp-jxgkeo6x:not([data-motion-enter="done"]) {
        opacity: 0;
    }
}

#comp-jxgkeo6x {
    width: 279px;
    height: auto;
}

#comp-jxgkeo70 {
    width: 279px;
    height: auto;
}

#comp-jxgkeo72 {
    width: 279px;
    height: auto;
}

#comp-jzitgs7f {
    --blendMode: normal;
    --textShadow: 0px 0px transparent;
    --textOutline: 0px 0px transparent;
}

#comp-jzitgs7f {
    width: 279px;
    height: auto;
}

#comp-jxgkeo74 {
    width: 22px;
    height: 22px;
}

#comp-lidzsj9g {
    --rd: 0px;
    --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    --shd: none;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --trans2: color 0.4s ease 0s;
    --txt: 251, 251, 251;
    --brw: 0px;
    --bg: 200, 101, 103;
    --brd: var(--color_11);
    --bgh: var(--color_17);
    --brdh: var(--color_12);
    --txth: var(--color_11);
    --bgd: 204, 204, 204;
    --alpha-bgd: 1;
    --brdd: 204, 204, 204;
    --alpha-brdd: 1;
    --txtd: 255, 255, 255;
    --alpha-txtd: 1;
    --alpha-txth: 1;
    --alpha-brdh: 0;
    --alpha-brd: 0;
    --alpha-bg: 1;
    --alpha-bgh: 1;
    --boxShadowToggleOn-shd: none;
    --alpha-txt: 1;
}

#comp-lidzsj9g {
    width: 301px;
    height: 69px;
}

#comp-jxmal10r {
    left: 0;
    margin-left: 0;
    width: 100%;
    min-width: 980px;
}

[data-mesh-id=comp-jxmal11yinlineContent] {
    height: auto;
    width: 100%;
}

[data-mesh-id=comp-jxmal11yinlineContent-gridContainer] {
    position: static;
    display: grid;
    height: auto;
    width: 100%;
    min-height: auto;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-columns: 100%;
}

[data-mesh-id=comp-jxmal11yinlineContent-gridContainer]>[id="comp-jxmal122"] {
    position: relative;
    margin: 41px 0px 42px calc((100% - 980px) * 0.5);
    left: -30px;
    grid-area: 1 / 1 / 4 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxmal11yinlineContent-gridContainer]>[id="comp-jxmal126"] {
    position: relative;
    margin: 113px 0px 11px calc((100% - 980px) * 0.5);
    left: 28px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
}

[data-mesh-id=comp-jxmal11yinlineContent-gridContainer]>[id="comp-jxmal12a"] {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 339px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}

#comp-jxmal11y {
    width: 980px;
}

#comp-jxmal122 {
    width: 333px;
    height: 322px;
}

#comp-jxmal126 {
    width: 946px;
    height: auto;
}

#comp-jxmal12a {
    --rd: 0px;
    --trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
    --shd: none;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --trans2: color 0.4s ease 0s;
    --txt: 49, 63, 79;
    --brw: 0px;
    --bg: 255, 255, 255;
    --brd: var(--color_11);
    --bgh: var(--color_17);
    --brdh: var(--color_12);
    --txth: var(--color_11);
    --bgd: 204, 204, 204;
    --alpha-bgd: 1;
    --brdd: 204, 204, 204;
    --alpha-brdd: 1;
    --txtd: 255, 255, 255;
    --alpha-txtd: 1;
    --alpha-bg: 1;
    --alpha-bgh: 1;
    --alpha-brd: 0;
    --alpha-brdh: 0;
    --alpha-txt: 1;
    --alpha-txth: 1;
    --boxShadowToggleOn-shd: none;
}

#comp-jxmal12a {
    width: 301px;
    height: 69px;
}

                                #bzld2 {
                                    width: auto;
                                    min-height: 40px;
                                }

#pageBackground_bzld2 {
    --bg-position: absolute;
    --bg-overlay-color: rgb(var(--color_11));
    --bg-gradient: none;
}

[class^="backgroundGroup"] :not(#pageBackground_bzld2) {
    --bg-position: absolute;
    --bg-overlay-color: rgb(var(--color_11));
    --bg-gradient: none;
}

#comp-lh3d5tpq {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxmbaamv {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxmbaaov {
    --bg-overlay-color: #228B22;
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxmc9hwq {
    --opacity: 0.1;
}

#comp-jxmbalxa {
    transform-origin: center 1.5px;
}

#comp-lh3d5tpr {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jy05446j {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jy05447e {
    --bg-overlay-color: rgb(251, 251, 251);
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-lh3d5tpr1 {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jzd5hjtn {
    transform-origin: center 1.5px;
}

#comp-lh3d5tpt {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxmamedc {
    --height: 217px;
    --width: 214px;
}

#comp-jxgk29bq {
    --min-height: 22px;
}

#comp-jzitf39f {
    --min-height: 6px;
}

#comp-itwyomte {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxmamecg {
    --height: 217px;
    --width: 214px;
}

#comp-jxgk4jwy {
    --min-height: 22px;
}

#comp-jxgk4jx6 {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-l256u5bo {
    --height: 217px;
    --width: 214px;
}

#comp-l256u5c4 {
    --min-height: 22px;
}

#comp-l256u5cl {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-lh3d5tpu {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    min-width: 980px;
}

#comp-jxgkdrcn {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxgkdret {
    --bg-overlay-color: rgb(251, 251, 251);
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jzd5igep {
    transform-origin: center 1.5px;
}

#comp-jxgky17m {
    --height: 217px;
    --width: 214px;
}

#comp-jxgky1ba {
    --min-height: 22px;
}

#comp-jzitiabq {
    --min-height: 70px;
}

#comp-jxgky1bm {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxgky172 {
    --height: 217px;
    --width: 214px;
    --mask-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' data-bbox='20 20 160 160' viewBox='20 20 160 160' height='200' width='200' xmlns='https://www.w3.org/2000/svg' data-type='shape'%3E%3Cg%3E%3Cpath d='M180 20v160H20V20h160z'/%3E%3C/g%3E%3C/svg%3E%0A");
    --mask-position: 0% 0%;
    --mask-size: 100% 100%;
    --mask-repeat: no-repeat;
}

#comp-jxgky1c4 {
    --min-height: 22px;
}

#comp-jzitigxq {
    --min-height: 22px;
}

#comp-jxgky1cc {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxgkob6d {
    --height: 217px;
    --width: 214px;
    --mask-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' data-bbox='20 20 160 160' viewBox='20 20 160 160' height='200' width='200' xmlns='https://www.w3.org/2000/svg' data-type='shape'%3E%3Cg%3E%3Cpath d='M180 20v160H20V20h160z'/%3E%3C/g%3E%3C/svg%3E%0A");
    --mask-position: 0% 0%;
    --mask-size: 100% 100%;
    --mask-repeat: no-repeat;
}

#comp-jxgkeo9v {
    --min-height: 22px;
}

#comp-jzith4a6 {
    --min-height: 28px;
}

#comp-jxgkeoa2 {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-lh3dlsjx {
    --height: 217px;
    --width: 214px;
}

#comp-lh3dlsjy3 {
    --min-height: 22px;
}

#comp-lh3dlsk1 {
    --min-height: 145px;
}

#comp-lh3dlsk21 {
    --min-height: 22px;
}

#comp-lh3dlsk31 {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxgky186 {
    --height: 224px;
    --width: 224px;
}

#comp-jxgky1ai {
    --min-height: 37px;
}

#comp-jxgky1am {
    --min-height: 25px;
}

#comp-jxgky1ap {
    --min-height: 129px;
}

#comp-jzithtbx {
    --min-height: 21px;
}

#comp-jxgky1as {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxgkeo48 {
    --height: 220px;
    --width: 220px;
}

#comp-jxgkeo8d {
    --min-height: 24px;
}

#comp-jxgkeo8g {
    --min-height: 27px;
}

#comp-jxgkeo8i {
    --min-height: 134px;
}

#comp-jzitgz9p {
    --min-height: 28px;
}

#comp-jxgkeo8k {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    width: 22px;
    height: 22px;
}

#comp-jxgko7ig {
    --height: 217px;
    --width: 214px;
}

#comp-jxgkeo6x {
    --min-height: 22px;
}

#comp-jxgkeo74 {
    --item-size: 22px;
    --item-margin: 0px 7px 0px 0px;
    --item-display: inline-block;
    height: 22px;
    width: 22px;
}

#comp-lidzsj9g {
    --shc-mutated-brightness: 100, 51, 52;
    --margin-start: 0px;
    --margin-end: 0px;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --label-align: center;
    --label-text-align: center;
}

#comp-jxmal10r {
    --bg-overlay-color: transparent;
    --bg-gradient: none;
    --padding: 0px;
    --margin: 0px;
    min-width: 980px;
    --firstChildMarginTop: -1px;
    --lastChildMarginBottom: -1px;
    --items-direction: row;
}

#comp-jxmal11y {
    --bg-overlay-color: #C2B280;
    --bg-gradient: none;
    width: 100%;
    --column-width: 980px;
    --column-flex: 980;
}

#comp-jxmal122 {
    --opacity: 0.5;
}

#comp-jxmal12a {
    --shc-mutated-brightness: 128, 128, 128;
    --margin-start: 0px;
    --margin-end: 0px;
    --fnt: normal normal 700 20px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    --label-align: center;
    --label-text-align: center;
}

